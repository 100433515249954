import React from 'react'
import { Img, Flex } from 'rendition'
import styled from 'styled-components'
import playButton from '../img/play.svg'

const Wrapper = styled(Flex)`
  background: url('${(props) => props.background}');
  background-size: cover;
`

const Overlay = styled.button`
  background: rgba(239, 248, 255, 0.3);
  flex: 1;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  border: none;
  box-shadow: none;
`

function Video({ video, videoPreview, videoThumbnail }) {
  return (
    <Wrapper my={4} width="100%" height="516px" background={videoThumbnail}>
      <Overlay>
        <Img src={playButton}></Img>
      </Overlay>
    </Wrapper>
  )
}

export default Video
