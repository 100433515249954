export const LINKEDIN_SHARE_URL =
  'https://www.linkedin.com/shareArticle/?mini=true&url='
export const TWITTER_SHARE_URL = 'https://twitter.com/share'
export const FACEBOOK_SHARE_URL =
  'https://www.facebook.com/sharer.php?s=100&p[url]='

export const getTwitterUrl = (content, url) => {
  return `${TWITTER_SHARE_URL}?url=${encodeURIComponent(
    url
  )}&text=${encodeURIComponent(content)}`
}

export const getFacebookUrl = (url) => {
  return `${FACEBOOK_SHARE_URL}${encodeURIComponent(url)}`
}

export const getLinkedInUrl = (url) => {
  return `${LINKEDIN_SHARE_URL}${encodeURIComponent(url)}`
}
