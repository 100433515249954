import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Box, Button } from 'rendition'

const MobileView = styled.aside`
  display: block;

  @media all and (min-width: ${(props) => {
      return props.theme.breakpoints[1]
    }}px) {
    display: none;
  }
`

const Toggle = styled(Button)`
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 6;
`

const List = styled(Box)`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 5;
  padding: 36px;
`

const FloatingMenu = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  return (
    <MobileView>
      <Toggle
        secondary
        fontSize={22}
        icon={<div>{isOpen ? '×' : '⋯'}</div>}
        onClick={handleToggle}
      />
      {isOpen && <List>{children}</List>}
    </MobileView>
  )
}

export default FloatingMenu
