import React from 'react'
import { Button, Flex, Link } from 'rendition'
import balenaLogo from '../img/balena-smaller.svg'

function DeployButton(props) {
  let deployUrl = `https://dashboard.balena-cloud.com/deploy?repoUrl=${props.repourl}`
  return (
    <Link
      tooltip="Deploy this project to your balenaCloud account"
      href={deployUrl}
      target="_blank"
    >
      <Button mt={2} primary>
        <Flex alignItems="center">
          <img
            style={{ marginRight: 4 }}
            height="16px"
            width="16px"
            src={balenaLogo}
            alt="Balena logo"
          />
          Deploy with balena
        </Flex>
      </Button>
    </Link>
  )
}

export default DeployButton
