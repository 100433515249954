import React from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

function Gallery(props) {
  const images = React.Children.toArray(props.children)
    .filter((child) => child?.props?.children)
    .map((child) => {
      return child.props.children
        .filter((c) => c?.props?.src)
        .map((c) => ({
          original: c?.props?.src,
          thumbnail: c?.props?.src,
          alt: c?.props?.alt,
        }))
    })
    .flat()

  return (
    <div>
      <ImageGallery items={images} />
    </div>
  )
}

export default Gallery
