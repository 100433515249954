/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase, capitalize } from 'lodash'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import {
  Flex,
  Box,
  Container,
  Txt,
  Link as RLink,
  Img,
  Heading,
  Divider,
  Badge,
} from 'rendition'
import { Markdown } from 'rendition/dist/extra/Markdown'
import { fontSizes } from 'rendition/dist/theme'
import FontAwesomeIcon from '../components/Icon'
import { palette } from '../utils/colors'
import styled from 'styled-components'
import Gallery from '../components/Gallery'
import DeployButton from '../components/DeployButton'
import Loadable from '@loadable/component'
import { Helmet } from 'react-helmet'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Video from '../components/Video'
import {
  getFacebookUrl,
  getLinkedInUrl,
  getTwitterUrl,
} from '../utils/socialShare'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import FloatingMenu from '../components/FloatingMenu'
const Comments = Loadable(() => import('../components/Comments'))
const Math = Loadable(() => import('../components/Math'))

const StyledBlockQuote = styled.blockquote`
  border-left: none !important;
  margin-top: -16px !important;
  font-family: CrimsonText !important;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.25;
  letter-spacing: normal;
  color: #00adee !important;

  & cite {
    display: block;
    font-weight: 400;
    color: #242b42;
    font-size: 14px;
    font-family: 'Source Sans Pro', Helvetica, sans-serif;
    font-style: normal;
  }
  & sub {
    bottom: 0;
    font-size: 100%;
    color: #656a7a;
  }
`

const Li = styled.li`
  padding: 4px 0px;
`

const BlockQuote = (props) => (
  <Flex py={4} mt="32px" flexDirection="column">
    <Txt.span color="primary.main" style={{ lineHeight: 0 }} fontSize="80px">
      &#8221;
    </Txt.span>
    <StyledBlockQuote {...props} />
  </Flex>
)

const FigCaption = styled.figcaption`
  font-style: italic;
  width: 70%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

export const BlogPostTemplate = ({
  postType,
  content,
  description,
  tags,
  title,
  helmet,
  publishedAt,
  updatedAt,
  difficulty,
  executionTime,
  budget,
  primaryAuthor,
  video,
  videoPreview,
  videoThumbnail,
  excerpt,
  slug,
}) => {
  return (
    <Box maxWidth="100%" pl={[0, 0, 3, 3]}>
      {helmet || ''}
      <Box>
        <Txt.span fontSize="12px" color="text.light">
          {publishedAt}
        </Txt.span>{' '}
        {updatedAt && (
          <Txt.span pl={1} fontSize="12px" color="text.light">
            {' '}
            / Last updated:{' '}
            <Txt.span bold fontSize="12px" color="text.light">
              {' '}
              {updatedAt}
            </Txt.span>
          </Txt.span>
        )}
      </Box>
      <Heading.h1 pb={3} style={{ fontSize: fontSizes[6] }}>
        {title}
      </Heading.h1>
      {postType !== 'article' && (
        <Flex
          mt={3}
          mb={2}
          bg={palette.aliceBlue}
          flexDirection={['column', 'column', 'row', 'row']}
          style={{ borderRadius: 5 }}
          px={3}
          py={[3, 3, 2, 2]}
        >
          <Flex alignItems="center" justifyContent={'start'}>
            <Txt.span color="primary.main">
              <FontAwesomeIcon icon={['far', 'stopwatch']} />
            </Txt.span>

            <Txt.span fontSize={1} pl={3}>
              <Txt.span bold>Execution time:</Txt.span> {executionTime}
            </Txt.span>
          </Flex>
          <Flex
            px={[0, 0, 4, 4]}
            py={[2, 2, 0, 0]}
            alignItems="center"
            justifyContent={'start'}
          >
            <Txt.span color="primary.main">
              <FontAwesomeIcon icon={['fas', 'dumbbell']} />
            </Txt.span>

            <Txt.span fontSize={1} pl={3}>
              <Txt.span bold>Difficulty:</Txt.span> {capitalize(difficulty)}
            </Txt.span>
          </Flex>
          <Flex alignItems="center" justifyContent={'start'}>
            <Txt.span color="primary.main">
              <FontAwesomeIcon icon={['fas', 'dollar-sign']} />
            </Txt.span>

            <Txt.span fontSize={1} pl={3}>
              <Txt.span bold>Cost:</Txt.span> {capitalize(budget)}
            </Txt.span>
          </Flex>
        </Flex>
      )}
      {description && <p>{description}</p>}
      {video && (
        <Video
          video={video}
          videoPreview={videoPreview}
          videoThumbnail={videoThumbnail}
        >
          {video}
        </Video>
      )}
      <Markdown
        componentOverrides={{
          img: (props) => (
            <Img {...props} style={{ maxWidth: '100%' }} py={2} />
          ),
          h1: (props) => (
            <Heading.h1
              {...props}
              fontSize="34px"
              mt={4}
              mb={3}
              id={
                props.children &&
                props.children.length === 1 &&
                typeof props.children[0] === 'string'
                  ? kebabCase(props.children[0].toLowerCase())
                  : null
              }
            />
          ),
          h2: (props) => (
            <Heading.h2
              {...props}
              fontSize="30px"
              mt={4}
              mb={3}
              id={
                props.children &&
                props.children.length === 1 &&
                typeof props.children[0] === 'string'
                  ? kebabCase(props.children[0].toLowerCase())
                  : null
              }
            />
          ),
          h3: (props) => (
            <Heading.h3
              {...props}
              fontSize="24px"
              mt={4}
              mb={3}
              id={
                props.children &&
                props.children.length === 1 &&
                typeof props.children[0] === 'string'
                  ? kebabCase(props.children[0].toLowerCase())
                  : null
              }
            />
          ),
          h4: (props) => (
            <Heading.h4
              {...props}
              fontSize="20px"
              style={{ fontWeight: 600 }}
              mt={4}
              mb={3}
              id={
                props.children &&
                props.children.length === 1 &&
                typeof props.children[0] === 'string'
                  ? kebabCase(props.children[0].toLowerCase())
                  : null
              }
            />
          ),
          h5: (props) => (
            <Heading.h5
              {...props}
              fontSize="20px"
              style={{ fontWeight: 600 }}
              mt={4}
              mb={3}
              id={
                props.children &&
                props.children.length === 1 &&
                typeof props.children[0] === 'string'
                  ? kebabCase(props.children[0].toLowerCase())
                  : null
              }
            />
          ),
          h6: (props) => (
            <Heading.h6
              {...props}
              fontSize="20px"
              style={{ fontWeight: 600 }}
              mt={4}
              mb={3}
              id={
                props.children &&
                props.children.length === 1 &&
                typeof props.children[0] === 'string'
                  ? kebabCase(props.children[0].toLowerCase())
                  : null
              }
            />
          ),
          p: (props) => (
            <Txt
              {...props}
              py={2}
              style={{ lineHeight: 1.63 }}
              margin="12px 0 0"
              fontSize={2}
            />
          ),
          li: (props) => <Li {...props} />,
          hr: (props) => <Divider {...props} my={4} />,
          blockquote: (props) => <BlockQuote {...props} />,
          gallery: (props) => <Gallery {...props} />,
          'deploy-with-balena': (props) => <DeployButton {...props} />,
          math: (props) => <Math {...props} />,
          figcaption: (props) => <FigCaption {...props} />,
        }}
        sanitizerOptions={null}
      >
        {content}
      </Markdown>
      {primaryAuthor && primaryAuthor.name && (
        <Flex mt={5} mb={3} alignItems="center">
          <Box maxHeight={64} maxWidth={64}>
            <PreviewCompatibleImage
              imageInfo={primaryAuthor?.avatar}
              height={64}
              width={64}
              style={{ borderRadius: '50%' }}
            />
          </Box>
          <Flex pl={3} flexDirection="column">
            <Link
              to={`/authors/${kebabCase(primaryAuthor.name)}/`}
              style={{
                color: '#00AEEF',
                textDecoration: 'none',
              }}
            >
              by {primaryAuthor.name}
            </Link>
            <Txt.span color="text.light" pt={1}>
              {primaryAuthor.bio}
            </Txt.span>
          </Flex>
        </Flex>
      )}

      <Divider mt={4} mb={3} />
      <Flex mb={6} mt={2} justifyContent="space-between">
        <Box>
          {tags?.map((tag) => (
            <RLink
              mr={2}
              is={Link}
              key={tag + `tag`}
              to={`/tags/${kebabCase(tag)}/`}
            >
              <Badge px={3} shade={16}>
                {tag}
              </Badge>
            </RLink>
          ))}
        </Box>
        <Flex alignItems="center">
          <Txt.span bold pr={2}>
            Share this post
          </Txt.span>
          <RLink
            blank
            href={getTwitterUrl(excerpt, `https://balena.io/blog${slug}`)}
          >
            <Txt.span color="gray.dark" fontSize={3} mx={2}>
              <FontAwesomeIcon icon={['fab', 'twitter']} />
            </Txt.span>
          </RLink>
          <RLink blank href={getFacebookUrl(`https://balena.io/blog${slug}`)}>
            <Txt.span color="gray.dark" fontSize={3} mx={2}>
              <FontAwesomeIcon icon={['fab', 'facebook-square']} />
            </Txt.span>
          </RLink>
          <RLink blank href={getLinkedInUrl(`https://balena.io/blog${slug}`)}>
            <Txt.span color="gray.dark" fontSize={3} mx={2}>
              <FontAwesomeIcon icon={faLinkedinIn} />
            </Txt.span>
          </RLink>
        </Flex>
      </Flex>
    </Box>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Container>
        <Flex
          pt={4}
          justifyContent={
            post.frontmatter.postType !== 'article' ? 'space-between' : 'center'
          }
          width="100%"
        >
          {post.frontmatter.postType !== 'article' && (
            <>
              <Box
                display={['none', 'none', 'block', 'block']}
                width={250}
                pr="20px"
              >
                <Txt fontSize="12px" color="text.light">
                  Content
                </Txt>
                <Markdown
                  componentOverrides={{
                    p: (props) => (
                      <Txt
                        {...props}
                        color="text.main"
                        bold
                        pt={2}
                        pb={1}
                        fontSize="14px"
                      />
                    ),
                    ul: 'div',
                    li: (props) => <Box {...props} py={1} color="text.main" />,
                    a: (props) => (
                      <RLink
                        {...props}
                        color="text.main"
                        style={{ fontSize: 14 }}
                      />
                    ),
                  }}
                >
                  {post.tableOfContents}
                </Markdown>
              </Box>
              <FloatingMenu>
                <Txt fontSize="12px" color="text.light">
                  Content
                </Txt>
                <Markdown
                  componentOverrides={{
                    p: (props) => (
                      <Txt
                        {...props}
                        color="text.main"
                        bold
                        pt={2}
                        pb={1}
                        fontSize="14px"
                      />
                    ),
                    ul: 'div',
                    li: (props) => <Box {...props} py={1} color="text.main" />,
                    a: (props) => (
                      <RLink
                        {...props}
                        color="text.main"
                        style={{ fontSize: 14 }}
                      />
                    ),
                  }}
                >
                  {post.tableOfContents}
                </Markdown>
              </FloatingMenu>
            </>
          )}
          <Box
            width={['100%', '100%', 'calc(100% - 250px)', 'calc(100% - 250px)']}
          >
            <BlogPostTemplate
              excerpt={post.frontmatter.excerpt}
              slug={post.fields.slug}
              primaryAuthor={post.frontmatter.primary_author}
              postType={post.frontmatter.postType}
              difficulty={post.frontmatter.difficulty}
              executionTime={post.frontmatter.execution_time}
              budget={post.frontmatter.budget}
              content={post.rawMarkdownBody}
              description={post.frontmatter.description}
              helmet={
                <Helmet>
                  <title>{post.frontmatter.title}</title>
                  <meta name="description" content={post.frontmatter.excerpt} />
                  <link
                    rel="canonical"
                    href={`https://www.balena.io/blog/${post.fields.slug}/`}
                  />

                  <meta property="og:type" content="article" />
                  <meta
                    property="og:title"
                    content={`${post.frontmatter.title}`}
                  />
                  <meta
                    property="og:description"
                    content={`${post.frontmatter.excerpt}`}
                  />
                  <meta
                    property="og:url"
                    content={`https://www.balena.io/blog/${post.fields.slug}/`}
                  />
                  <meta
                    property="og:image"
                    content={`${post.frontmatter.feature_image}`}
                  />
                  <meta
                    property="article:published_time"
                    content={post.frontmatter.published_at}
                  />
                  {!!post.frontmatter.updated_at && (
                    <meta
                      property="article:modified_time"
                      content={post.frontmatter.updated_at}
                    />
                  )}
                  {post.frontmatter.tags &&
                    post.frontmatter.tags.map((tag) => (
                      <meta property="article:tag" content={tag} />
                    ))}

                  <meta name="twitter:card" content="summary_large_image" />
                  <meta
                    name="twitter:title"
                    content={`${post.frontmatter.title}`}
                  />
                  <meta
                    name="twitter:description"
                    content={`${post.frontmatter.excerpt}`}
                  />
                  <meta
                    name="twitter:url"
                    content={`https://blog.balena.io/${post.fields.slug}/`}
                  />
                  <meta
                    name="twitter:image:src"
                    content={`${post.frontmatter.feature_image}`}
                  />
                  {post.frontmatter.primary_author && (
                    <meta name="twitter:label1" content="Written by" />
                  )}
                  {post.frontmatter.primary_author && (
                    <meta
                      name="twitter:data1"
                      content={post.frontmatter.primary_author.name}
                    />
                  )}
                  {post.frontmatter.tags &&
                    post.frontmatter.tags.length > 0 && (
                      <meta name="twitter:label2" content="Filed under" />
                    )}
                  {post.frontmatter.tags &&
                    post.frontmatter.tags.length > 0 && (
                      <meta
                        name="twitter:data2"
                        content={post.frontmatter.tags.join(', ')}
                      />
                    )}
                </Helmet>
              }
              tags={post.frontmatter.tags}
              title={post.frontmatter.title}
              publishedAt={post.frontmatter.published_at}
              updatedAt={post.frontmatter.updated_at}
            />
            <Comments
              commentsId={post.frontmatter.discourseTopicId}
              url={`https://blog.balena.io/${post.fields.slug}`}
            />
          </Box>
        </Flex>
      </Container>
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      rawMarkdownBody
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        postType
        discourseTopicId
        tags
        authors
        excerpt
        feature_image
        difficulty
        execution_time
        budget
        primary_author {
          name
          bio
          avatar {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        published_at(formatString: "DD MMMM YYYY")
        updated_at(formatString: "DD MMM YYYY")
      }
      timeToRead
      tableOfContents
    }
  }
`
